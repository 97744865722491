import clsx from 'clsx';
import { graphql, Link, useStaticQuery } from 'gatsby';
import mailgo from 'mailgo';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Logo from '../../assets/svg/logo.svg';
import TwitterLogo from '../../assets/svg/twitter.svg';

const Footer = ({ showBorder = true }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            socials {
              twitter {
                url
              }
            }
          }
        }
      }
    `
  );
  const { t } = useTranslation();

  useEffect(() => {
    mailgo();
  }, []);

  return (
    <footer className="bg-oxford-blue">
      <div
        className={clsx(
          'px-4 sm:px-6 mx-auto max-w-6xl',
          showBorder ? 'border-t border-gray-200' : ''
        )}
      >
        <div className="grid sm:grid-cols-12 gap-8 py-8 md:py-12">
          <div className="sm:col-span-12 lg:col-span-4">
            <div className="mb-2">
              <Link to="/" className="inline-block">
                <Logo
                  alt={t(`${site.siteMetadata.title} Logo`)}
                  className="w-8 h-8 text-white"
                />
              </Link>
            </div>
            <div className="text-sm">
              <Link to="/terms" className="text-gray-400">
                {t('Terms & Conditions')}
              </Link>{' '}
              ·{' '}
              <Link to="/privacy" className="text-gray-400">
                {t('Privacy Policy')}
              </Link>
            </div>
          </div>

          <div className="sm:col-span-6 md:col-span-3 lg:col-span-2">
            <h6 className="mb-2 font-medium text-white">{t('Integrations')}</h6>
            <ul className="text-sm">
              <li className="mb-2">
                <Link to="/integrations/make" className="text-gray-400">
                  {t('Make (formerly Integromat)')}
                </Link>
              </li>
              <li className="mb-2">
                <Link to="/integrations/zapier" className="text-gray-400">
                  {t('Zapier')}
                </Link>
              </li>
              <li className="mb-2">
                <span className="text-gray-600 hover:text-gray-900 no-underline">
                  {t('More soon')}
                </span>
              </li>
            </ul>
          </div>

          <div className="sm:col-span-6 md:col-span-3 lg:col-span-2">
            <h6 className="mb-2 font-medium text-white">{t('Resources')}</h6>
            <ul className="text-sm">
              <li className="mb-2">
                <Link to="/help" className="text-gray-400">
                  {t('Knowledge Base')}
                </Link>
              </li>
              <li className="mb-2">
                <Link to="/blog" className="text-gray-400">
                  {t('Blog')}
                </Link>
              </li>
            </ul>
          </div>

          <div className="sm:col-span-6 md:col-span-3 lg:col-span-2">
            <h6 className="mb-2 font-medium text-white">{t('Company')}</h6>
            <ul className="text-sm">
              <li className="mb-2">
                <Link to="/about" className="text-gray-400">
                  {t('About')}
                </Link>
              </li>
              <li className="mb-2">
                <Link to="/pricing" className="text-gray-400">
                  {t('Pricing')}
                </Link>
              </li>
              <li className="mb-2">
                <a href="mailto:support@copybara.cc" className="text-gray-400">
                  {t('Contact')}
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="flex flex-row-reverse justify-between items-center py-4 md:py-8 border-t border-gray-200">
          <ul className="flex md:ml-4">
            <li>
              <a
                href={site.siteMetadata.socials.twitter.url}
                target="_blank"
                rel="noreferrer"
                className="flex justify-center items-center text-oxford-blue bg-white rounded-full shadow"
                aria-label="Twitter"
              >
                <TwitterLogo alt="Twitter" className="w-8 h-8" />
              </a>
            </li>
          </ul>

          <div className="mr-4 text-sm text-white">
            &copy; {new Date().getFullYear()} {site.siteMetadata.title}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
