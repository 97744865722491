import React from 'react';

import Footer from './components/Footer';
import Header from './components/Header';

const Layout = ({
  children,
  showFooter = true,
  showHeader = true,
  showBorder = false,
}) => {
  return (
    <div className="flex overflow-hidden flex-col min-h-screen">
      {showHeader && <Header />}
      <main role="main" className="flex-grow pt-20 md:pt-36 pb-16">
        {children}
      </main>
      {showFooter && <Footer showBorder={showBorder} />}
    </div>
  );
};

export default Layout;
