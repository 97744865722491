import clsx from 'clsx';
import React, { useEffect, useState } from 'react';

import Nav from './Nav';

const Header = () => {
  const [top, setTop] = useState(true);

  useEffect(() => {
    const scrollHandler = () => {
      if (window.pageYOffset > 10) {
        setTop(false);
      } else {
        setTop(true);
      }
    };

    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [top]);

  return (
    <header
      role="main"
      className={clsx(
        'fixed z-30 w-full md:bg-opacity-90',
        top ? '' : 'bg-white shadow-lg backdrop-blur-sm'
      )}
    >
      <div className="px-4 sm:px-6 lg:px-16 mx-auto max-w-7xl">
        <Nav />
      </div>
    </header>
  );
};

export default Header;
