import { graphql, Link, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Hamburger from '../../assets/svg/hamburger.svg';
import Logo from '../../assets/svg/logo.svg';
import clsx from 'clsx';

const Nav = () => {
  const [showMenu, setShowMenu] = useState(false);
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            appUrl
          }
        }
      }
    `
  );
  const { t } = useTranslation();

  return (
    <div className="flex justify-between items-center py-3">
      <div className="flex flex-shrink-0 items-center mr-4">
        <Link
          to="/"
          className="inline-block mr-3"
          aria-label={t(`${site.siteMetadata.title} Home`)}
        >
          <Logo
            alt={t(`${site.siteMetadata.title} Logo`)}
            className="w-9 h-9 text-oxford-blue"
          />
        </Link>
        <Link
          to="/"
          className="font-oxygen text-2xl font-bold text-oxford-blue no-underline hover:no-underline"
          aria-label={t(`${site.siteMetadata.title} Home`)}
        >
          {site.siteMetadata.title}
        </Link>
      </div>

      <nav
        className={clsx(
          showMenu
            ? 'absolute top-14 right-4 w-4/5 bg-white border border-gray-100 shadow-lg'
            : 'hidden md:flex flex-grow'
        )}
      >
        <ul
          className={clsx(
            'flex',
            showMenu
              ? 'flex-col'
              : 'flex flex-wrap flex-grow justify-end items-center'
          )}
        >
          <li>
            <Link
              to="/pricing"
              className="flex items-center py-2 px-5 text-lg font-bold text-oxford-blue no-underline"
            >
              {t('Pricing')}
            </Link>
          </li>
          <li>
            <Link
              to="/blog"
              className="flex items-center py-2 px-5 text-lg font-bold text-oxford-blue no-underline"
            >
              {t('Blog')}
            </Link>
          </li>
          <li>
            <Link
              to="/help"
              className="flex items-center py-2 px-5 text-lg font-bold text-oxford-blue no-underline"
            >
              {t('Docs')}
            </Link>
          </li>
          <li>
            <a
              href={site.siteMetadata.appUrl}
              className="flex items-center py-2 px-5 text-lg font-bold text-oxford-blue no-underline"
            >
              {t('Log in')}
            </a>
          </li>
        </ul>
      </nav>
      <div className="md:hidden -my-1 ml-2">
        <button
          type="button"
          className="flex justify-center items-center"
          onClick={() => setShowMenu(!showMenu)}
        >
          <span className="sr-only">Navigation</span>
          <Hamburger className="w-8 h-8" />
        </button>
      </div>
    </div>
  );
};

export default Nav;
